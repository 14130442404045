import React, { useState } from "react"
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Row,
  Col,
} from "reactstrap"
import classnames from "classnames"

// Charity Pics Pics
import CharityLogo from "../images/charity/red-cross.png"
import amerRedCross from "../images/charity/amerRedCross.png"
import StJude from "../images/charity/StJude.png"
import Msd from "../images/charity/MSD.png"
import FoodBank from "../images/charity/FoodBank.png"
import FoodForHungry from "../images/charity/FoodForHungry.png"
import KLove from "../images/charity/K-Love.png"
import Pf from "../images/charity/PF.png"
import Cta from "../images/charity/CTA.png"
import apsca from "../images/charity/apsca.png"
import charlieshouse from "../images/charity/charlies-house.png"
import tunneltowersfoundation from "../images/charity/tunnel-towers-foundation.png"

const ExampleChar = props => {
  const [activeTab, setActiveTab] = useState("1")

  const toggle = tab => {
    if (activeTab !== tab) setActiveTab(tab)
  }

  return (
    <div className="about-us-tabs">
      <div className="container-fluid" style={{ backgroundColor: "#F9F9F9" }}>
        <div
          className="container"
          style={{ paddingTop: "200px", paddingBottom: "100px" }}
        >
          <h1 className="text-center team-flair mb-5 text-dark">
            PeerSource Charities
          </h1>
          <h4 className="text-center mb-5 text-dark">
            PeerSource proudly supports several local, national, and
            international charities. We LOVE rewarding our friends with referral
            fees when we place technology talent or when we’re introduced to new
            clients. For those with a conflict of interest or ones that prefer
            to not have the referral dollars, we invite and greatly appreciate
            the opportunity to donate that money to one or multiple charity
            partners as shown below.
          </h4>
          <Nav
            tabs
            className="d-flex justify-content-center align-items-center"
          ></Nav>
          <Nav
            tabs
            className="d-flex justify-content-center align-items-center"
          >
            {/* United MSD Foundation */}
            <NavItem className="m-4">
              <NavLink className={classnames({ active: activeTab === "11" })}>
                <a href="https://curemsd.org/" target="_blank">
                  {" "}
                  <img alt="" className="team-portrait" src={Msd} />
                </a>
              </NavLink>
              <div className="team-info text-center mt-3">
                <h5 className="mb-0">United MSD</h5>
                <h5 className="mb-0">Foundation</h5>
              </div>
            </NavItem>
            {/* St JUDE */}
            <NavItem
              className="m-4"
              style={{ position: "relative", top: "8px" }}
            >
              <NavLink className={classnames({ active: activeTab === "8" })}>
                <a href="https://www.stjude.org/" target="_blank">
                  {" "}
                  <img alt="" className="team-portrait" src={StJude} />
                </a>
              </NavLink>
              <div className="team-info text-center mt-3">
                <h5 className="mb-0">St. Jude Children’s</h5>
                <h5 className="mb-0">Research Hospital </h5>
              </div>
            </NavItem>
            {/* American Red Cross: */}
            <NavItem className="m-4">
              <NavLink className={classnames({ active: activeTab === "10" })}>
                <a href="https://www.redcross.org/" target="_blank">
                  {" "}
                  <img alt="" className="team-portrait" src={amerRedCross} />
                </a>
              </NavLink>
              <div className="team-info text-center mt-3">
                <h5 className="mb-0">American</h5>
                <h5 className="mb-0">Red Cross</h5>
              </div>
            </NavItem>
            {/* Food Bank of the Rockies:Food Bank of the Rockies: */}
            <NavItem className="m-4">
              <NavLink className={classnames({ active: activeTab === "9" })}>
                <a href="https://www.foodbankrockies.org/" target="_blank">
                  {" "}
                  <img alt="" className="team-portrait" src={FoodBank} />
                </a>
              </NavLink>

              <div className="team-info text-center mt-3">
                <h5 className="mb-0">Food Bank</h5>
                <h5 className="mb-0">of the Rockies</h5>
              </div>
            </NavItem>
            {/* Food for the Hungry:           */}
            <NavItem className="m-4">
              <NavLink className={classnames({ active: activeTab === "17" })}>
                <a href="https://www.fh.org/" target="_blank">
                  {" "}
                  <img alt="" className="team-portrait" src={FoodForHungry} />
                </a>
              </NavLink>

              <div className="team-info text-center mt-3">
                <h5 className="mb-0"> Food for</h5>
                <h5 className="mb-0"> the Hungry</h5>
              </div>
            </NavItem>
            {/* K-Love */}
            <NavItem className="m-5">
              <NavLink className={classnames({ active: activeTab === "13" })}>
                <a href="http://www.klove.com/" target="_blank">
                  {" "}
                  <img alt="" className="team-portrait" src={KLove} />
                </a>
              </NavLink>

              <div className="team-info text-center mt-3">
                <h5 className="mb-0">K-Love</h5>
                <h5 className="mb-0"></h5>
              </div>
            </NavItem>

            {/* Pheasants Forever */}

            <NavItem className="m-4">
              <NavLink className={classnames({ active: activeTab === "14" })}>
                <a href="https://www.pheasantsforever.org/" target="_blank">
                  {" "}
                  <img alt="" className="team-portrait" src={Pf} />
                </a>
              </NavLink>

              <div className="team-info text-center mt-3">
                <h5 className="mb-0">Pheasants</h5>
                <h5 className="mb-0">Forever</h5>
              </div>
            </NavItem>

            {/* American Society for the Prevention of Cruelty to Animals:  https://www.aspca.org/
             */}

            <NavItem className="m-4">
              <NavLink className={classnames({ active: activeTab === "15" })}>
                <a href=" https://www.aspca.org/" target="_blank">
                  {" "}
                  <img alt="" className="team-portrait" src={apsca} />
                </a>
              </NavLink>

              <div className="team-info text-center mt-3">
                <h5 className="mb-0">American Society for </h5>
                <h5 className="mb-0">the Prevention of </h5>
                <h5 className="mb-0">Cruelty to Animals</h5>
              </div>
            </NavItem>

            {/* Charlie’s House:  https://www.charlieshouse.org/
             */}

            <NavItem className="m-4">
              <NavLink className={classnames({ active: activeTab === "15" })}>
                <a href=" https://www.charlieshouse.org/" target="_blank">
                  {" "}
                  <img alt="" className="team-portrait" src={charlieshouse} />
                </a>
              </NavLink>

              <div className="team-info text-center mt-3">
                <h5 className="mb-0">Charlie’s House</h5>
              </div>
            </NavItem>

            {/* Wounded Warrior Project:  https://www.woundedwarriorproject.org
             */}
            <NavItem className="m-4">
              <NavLink className={classnames({ active: activeTab === "15" })}>
                <a
                  href="https://t2t.org/"
                  target="_blank"
                >
                  {" "}
                  <img alt="" className="team-portrait" src={tunneltowersfoundation} />
                </a>
              </NavLink>

              <div className="team-info text-center mt-3">
                <h5 className="mb-0">Tunnel to Towers</h5>
                <h5 className="mb-0">Foundation</h5>
              </div>
            </NavItem>
          </Nav>
          <h5 className="text-center my-5 text-dark">
            We’re sorry, but at this time we are not able to donate to charities
            outside of this list via PeerSource. That said, each year we do
            review where contributions go and are always interested in suggested
            causes that are important to our extended PeerSource family. Feel
            free to reach out to us{" "}
            <a href="/contact-us" target="_self">
              here
            </a>
            , and include the charity name, website, and reason why you’d love
            to see PeerSource support them.
          </h5>
        </div>
      </div>
    </div>
  )
}

export default ExampleChar
